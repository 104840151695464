import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MercuryLoaderComponent } from 'src/app/_primeng/shared/mercury-loader/mercury-loader.component';


@NgModule({
  declarations: [MercuryLoaderComponent],
  imports: [
    CommonModule
  ],
  exports: [
    MercuryLoaderComponent
  ]
})
export class SharedModule { }
