// China
export const locale = {
  lang: "ch",
  "data": {
    "COMMON": {
      "CANCEL": "取消",
      "SUBMIT": "提交",
      "NO": "否",
      "YES": "是",
      "YES_CLOSE_AND_LOSE_CHANGES": "是，关闭并丢失更改",
      "CLOSE_AND_DISCARD": "关闭并放弃",
      "SAVE_UPDATED_DETAILS": "保存更新的详细信息",
      "VALIDATION": {
        "SOMETHING_WENT_WRONG": "哎呀，好像出了点问题！请再试一次。",
        "SUCCESSFULLY_UPDATED": "更新成功",
        "ANY_CHANGES_MADE_WILL_BE_LOST": "所有更改将会丢失。",
        "ARE_YOU_SURE_YOU_WANT_TO_PROCEED": "你确定要继续吗？"
      }
    },
    "NAVBAR": {
      "HELLO": "你好",
      "MY_DETAILS": "我的详情",
      "MY_CONTACT_METHODS": "我的联系方式",
      "MY_DATA_CONSENTS": "我的数据同意书",
      "LOGOUT": "登出",
      "SELECT_LANGUAGE": "选择语言",
      "MENU": "菜单",
      "CLOSE": "关闭",
      "SETTINGS": "设置",
      "TERMSANDCONDITIONS": "条款和条件",
      "LANGUAGE": {
        "ENLABEL": "英语",
        "CHLABEL": "中文",
        "ESLABEL": "西班牙语",
        "JALABEL": "日语",
        "DELABEL": "德语",
        "FRLABEL": "法语"
      },
      "MENU_ITEM": {
        "CURRENT_PLACEMENT": "我的当前职位",
        "COMPLIANCE": "我的合规性",
        "TIMESHEETS": "我的时间表",
        "EXPENSES": "我的费用"
      }
    },
    "AUTH": {
      "LOGIN": {
        "BUTTON": {
          "SIGN_IN": "登录"
        },
        "LABEL": {
          "EMAIL": "电子邮件",
          "PASSWORD": "密码",
          "FORGOT_PASSWORD": "忘记密码？"
        },
        "VALIDATION": {
          "EMAIL_INVALID": "无效的电子邮件地址",
          "EMAIL_MAX_LENGTH": "电子邮件长度不得超过{{value}}个字符",
          "EMAIL_REQUIRED": "必须输入电子邮件",
          "LOGIN_FAILED": "登录详情不正确",
          "PASSWORD_MAX_LENGTH": "密码长度不得超过{{value}}个字符",
          "PASSWORD_MIN_LENGTH": "密码长度不得少于{{value}}个字符",
          "PASSWORD_REQUIRED": "必须输入密码"
        }
      },
      "FORGOT_PASSWORD": {
        "ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD": "输入您的电子邮件以重置密码",
        "FORGOTTEN_PASSWORD": "忘记密码？",
        "BUTTON": {
          "OK_GOT_IT": "好的，明白了！"
        },
        "LABEL": {
          "EMAIL": "电子邮件",
          "EMAIL_SENT_MESSAGE1": "我们已将电子邮件发送到您指定的地址以重置密码。",
          "EMAIL_SENT_MESSAGE2": "如果您没有收到，请检查您的垃圾邮件文件夹，并确认输入的电子邮件地址是否正确。",
          "EMAIL_SENT_TITLE": "恢复邮件已发送！",
          "SUCCESS": "您的帐户已成功重置。"
        },
        "VALIDATION": {
          "EMAIL_INVALID": "无效的电子邮件地址",
          "EMAIL_MAX_LENGTH": "电子邮件长度不得超过{{value}}个字符",
          "EMAIL_REQUIRED": "必须输入电子邮件"
        }
      },
      "RESET_PASSWORD": {
        "ENTER_NEW_PASSWORD_DETAILS": "输入新密码详情",
        "RESET_PASSWORD": "重置密码",
        "BUTTON": {},
        "LABEL": {
          "CONFIRM_NEW_PASSWORD": "确认新密码",
          "CREATE_NEW_PASSWORD": "创建新密码",
          "OLD_PASSWORD": "旧密码",
          "PASSWORD": "密码"
        },
        "VALIDATION": {
          "PASSWORDS_DONT_MATCH": "'密码'和'确认密码'不匹配",
          "PASSWORD_MAX_LENGTH": "密码长度不得超过{{value}}个字符",
          "PASSWORD_MIN_LENGTH": "密码长度不得少于{{value}}个字符",
          "PASSWORD_PATTERN": "必须包含大写和小写字母、一个数字以及以下字符之一：-+_!@#$%^&*.,?",
          "PASSWORD_REQUIRED": "必须输入密码",
          "REGISTRATION_DETAILS_INCORRECT": "注册详情不正确"
        }
      }
    },
    "MY_CONTACT_METHODS": {
      "CONTACT_PREFERENCES": "联系偏好",
      "PLEASE_SELECT_HOW_YOU_WISH_TO_BE_CONTACTED": "请选择您希望被联系的方式。",
      "BUTTON": {},
      "LABEL": {
        "RECEIVE": "接收",
        "DO_NOT_RECEIVE": "不接收",
        "SALES_EMAIL": "销售电子邮件",
        "EMAIL": "电子邮件",
        "PHONE_CALL": "电话",
        "TEXT_MESSAGE": "短信"
      },
      "VALIDATION": {
        "ERROR_UPDATING": "更新联系偏好时出错"
      }
    }
  }
};
